import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ClickService } from 'src/app/components/br-select-2/br-select.component';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { MensagemService } from 'src/app/services/mensagem.service';
import { PerfilUsuario } from 'src/models/enums-model';
import { ETiposUsuarioNovidade } from '../cadastro-novidades/cadastro-novidades.model';
import { CadastroNovidadesService } from '../cadastro-novidades/cadastro-novidades.service';
import { ViewNovidades } from './exibir-novidades.model';

@Component({
  selector: 'app-exibir-novidades',
  templateUrl: './exibir-novidades.page.html',
  styleUrls: ['./exibir-novidades.page.scss'],
})
export class ExibirNovidadesPage implements OnInit, OnDestroy {
  @ViewChild('divNovidades') divNovidades: ElementRef;

  visaoCidadao = false; // = this.getModoCidadao();
  viewNovidades$: Observable<ViewNovidades>;
  tipoUsuario: ETiposUsuarioNovidade;
  ocultarNovidade = false;

  private novidadeClickSub: Subscription;
  private toggleCidadao = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private global: GlobalService,
    private mensagem: MensagemService,
    private clickService: ClickService,
    private novidadesService: CadastroNovidadesService
  ) {}

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.clickService.documentClickedTarget.next(event.target);
  }

  ngOnInit() {
    // const toggleCidadao = this.api.obterParametrosNav('toggleCidadao');
    // if (toggleCidadao) {
    //   this.toggleCidadao = true;
    //   this.visaoCidadao = true;
    // }

    setTimeout(() => {
      this.ligarListener();
    }, 200);
  }

  ngOnDestroy() {
    this.desligarListener();
  }

  ionViewDidEnter() {
    this.setModoCidadao();

    this.tipoUsuario = this.getTipoUsuario(this.visaoCidadao);
    this.carregar();
  }

  carregar() {
    this.viewNovidades$ = this.carregarNovidades$();
  }

  carregarNovidades$(): Observable<ViewNovidades> {
    const novidades$ = [
      this.novidadesService.consultaNovidades$(ETiposUsuarioNovidade.normal).pipe(map((ret) => ret[0])),
      this.novidadesService.consultaNovidades$(ETiposUsuarioNovidade.cidadao).pipe(map((ret) => ret[0])),
    ];

    const retorno$ = forkJoin(novidades$).pipe(
      map((ret) => {
        if (!ret || !ret[0] || !ret[1]) {
          return null;
        }
        const view = new ViewNovidades(ret);
        if (this.isNovidadeOculta(view)) {
          return null;
        }
        return view;
      }),
      catchError((err) => {
        if (String(err.message).startsWith('Cannot read properties of undefined')) {
          this.continuar();
        } else {
          this.mensagem.toastDanger('Erro ao carregar novidades');
        }
        return throwError(err);
      }),
      tap((ret: ViewNovidades) => {
        if (!ret) {
          this.continuar();
        }
      })
    );
    return retorno$;
  }

  getTipoUsuario(visaoCidadao: boolean): ETiposUsuarioNovidade {
    const tipoUsuario: ETiposUsuarioNovidade = visaoCidadao
      ? ETiposUsuarioNovidade.cidadao
      : ETiposUsuarioNovidade.normal;
    return tipoUsuario;
  }

  setModoCidadao() {
    const historyState = history.state.toggleCidadao;

    if (historyState) {
      this.toggleCidadao = true;
      this.visaoCidadao = true;
    } else {
      const perfilUsuario = this.global.getUsuario().getPerfilCadin().toLocaleUpperCase();
      const modoCidadao = perfilUsuario === PerfilUsuario.CIDADAO.toLocaleUpperCase();
      this.visaoCidadao = modoCidadao;
    }
  }

  getModoCidadao(): boolean {
    const perfilUsuario = this.global.getUsuario().getPerfilCadin().toLocaleUpperCase();
    const modoCidadao = perfilUsuario === PerfilUsuario.CIDADAO.toLocaleUpperCase();
    return modoCidadao;
  }

  isNovidadeOculta(view: ViewNovidades): boolean {
    if (view.oculto || view.getNovidadePorTipo(this.tipoUsuario)?.oculto) {
      return true;
    }
    return false;
  }

  continuar() {
    this.router.navigate(['principal/inicio'], { replaceUrl: true, state: { toggleCidadao: this.toggleCidadao } });
  }

  continuarOnClick(viewNovidades: ViewNovidades) {
    if (this.ocultarNovidade) {
      viewNovidades.ocultarNovidade(this.tipoUsuario);
    }
    this.continuar();
  }

  private ligarListener() {
    this.novidadeClickSub = this.clickService.documentClickedTarget.subscribe((target) =>
      this.documentListener(target)
    );
  }

  private desligarListener() {
    this.novidadeClickSub?.unsubscribe();
  }

  private documentListener(target: any): void {
    if (!this.divNovidades.nativeElement.contains(target)) {
      this.desligarListener();
      this.continuarOnClick(null);
    }
  }
}
