/**
 * CR 07/2018
 * Classe com informações dos ambientes
 */

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

export enum Ambientes {
  LOCAL,
  DES,
  HOM,
  PRD,
  DES_NOVO
}

interface IAmbiente {
  ambiente: Ambientes;
  local: string;
  descricao: string;
  urlBase: string;
  urlBaseAuth: string;
  urlSaidaGov: string;
  urlCadastro: string;
  urlCadastroREST: string;
  urlRemessa: string;
  urlCadinSida: string;
  urlValidador: string;
  urlExportador: string;
  urlBaseAuthSso: string;
}

export class AmbienteModel {
  public ambiente: Ambientes;

  private readonly _ambientes: IAmbiente[] = [
    {
      ambiente: Ambientes.LOCAL,
      descricao: 'Local',
      local: 'localhost',
      urlBase: 'https://des-novocadin.np.estaleiro.serpro.gov.br/backend/api',
      urlBaseAuth: 'https://des-novocadin.np.estaleiro.serpro.gov.br/backend/api/autenticacao/urlTratamentoFrontLocal',
      urlSaidaGov:
        'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=http://des-novocadin.np.estaleiro.serpro.gov.br/',
      urlCadastro: 'https://des-novocadin.np.estaleiro.serpro.gov.br/cadastro/api',
      urlCadastroREST: 'https://des-novocadin.np.estaleiro.serpro.gov.br/cadastro/apirest',
      urlRemessa: 'https://des-novocadin.np.estaleiro.serpro.gov.br/remessa/api/cadin',
      urlCadinSida: 'https://des-sida.estaleiro.serpro.gov.br/cadinsida-api/api/cadinsida',
      urlValidador: 'http://localhost:8000/validador',
      urlExportador: 'https://des-novocadin.np.estaleiro.serpro.gov.br/exportador',
      urlBaseAuthSso: 'https://des-novocadin.np.estaleiro.serpro.gov.br/authsso/api'
    },
    {
      ambiente: Ambientes.DES,
      descricao: 'Desenvolvimento',
      local: 'des-novocadin.estaleiro.serpro.gov.br',
      urlBase: 'https://des-novocadin.estaleiro.serpro.gov.br/backend/api',
      urlBaseAuth: 'https://des-novocadin.estaleiro.serpro.gov.br/backend/api/autenticacao/url',
      urlSaidaGov:
        'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=http://des-novocadin.estaleiro.serpro.gov.br/',
      urlCadastro: 'https://des-novocadin.estaleiro.serpro.gov.br/cadastro/api',
      urlCadastroREST: 'https://des-novocadin.estaleiro.serpro.gov.br/cadastro/apirest',
      urlRemessa: 'https://des-novocadin.estaleiro.serpro.gov.br/remessa/api/cadin',
      urlCadinSida: 'https://des-sida.estaleiro.serpro.gov.br/cadinsida-api/api/cadinsida',
      urlValidador: 'https://des-novocadin.estaleiro.serpro.gov.br/validador',
      urlExportador: 'https://des-novocadin.estaleiro.serpro.gov.br/exportador',
      urlBaseAuthSso: 'https://des-novocadin.estaleiro.serpro.gov.br/authsso/api'
    },
    {
      ambiente: Ambientes.HOM,
      descricao: 'Homologação',
      local: 'hom-novocadin.estaleiro.serpro.gov.br',
      urlBase: 'https://hom-novocadin.estaleiro.serpro.gov.br/backend/api',
      urlBaseAuth: 'https://hom-novocadin.estaleiro.serpro.gov.br/backend/api/autenticacao/url',
      urlSaidaGov:
        'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=http://hom-novocadin.estaleiro.serpro.gov.br/',
      urlCadastro: 'https://hom-novocadin.estaleiro.serpro.gov.br/cadastro/api',
      urlCadastroREST: 'https://hom-novocadin.estaleiro.serpro.gov.br/cadastro/apirest',
      urlRemessa: 'https://hom-novocadin.estaleiro.serpro.gov.br/remessa/api/cadin',
      urlCadinSida: 'https://hom-sida.estaleiro.serpro.gov.br/cadinsida-api/api/cadinsida',
      urlValidador: 'https://hom-novocadin.estaleiro.serpro.gov.br/validador',
      urlExportador: 'https://hom-novocadin.estaleiro.serpro.gov.br/exportador',
      urlBaseAuthSso: 'https://hom-novocadin.estaleiro.serpro.gov.br/authsso/api'
    },
    {
      ambiente: Ambientes.PRD,
      descricao: 'Produção',
      local: 'cadin.pgfn.gov.br',
      urlBase: 'https://cadin.pgfn.gov.br/backend/api',
      urlBaseAuth: 'https://cadin.pgfn.gov.br/backend/api/autenticacao/url',
      urlSaidaGov: 'https://sso.acesso.gov.br/logout?post_logout_redirect_uri=https://cadin.pgfn.gov.br/',
      urlCadastro: 'https://cadin.pgfn.gov.br/cadastro/api',
      urlCadastroREST: 'https://cadin.pgfn.gov.br/cadastro/apirest',
      urlRemessa: 'https://cadin.pgfn.gov.br/remessa/api/cadin',
      urlCadinSida: 'https://sida.estaleiro.serpro.gov.br/cadinsida-api/api/cadinsida',
      urlValidador: 'https://cadin.pgfn.gov.br/validador',
      urlExportador: 'https://cadin.pgfn.gov.br/exportador',
      urlBaseAuthSso: 'https://cadin.pgfn.gov.br/authsso/api'
    },
    {
      ambiente: Ambientes.DES_NOVO,
      descricao: 'Desenvolvimento',
      local: 'des-novocadin.np.estaleiro.serpro.gov.br',
      urlBase: 'https://des-novocadin.np.estaleiro.serpro.gov.br/backend/api',
      urlBaseAuth: 'https://des-novocadin.np.estaleiro.serpro.gov.br/backend/api/autenticacao/url',
      urlSaidaGov:
        'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=http://des-novocadin.np.estaleiro.serpro.gov.br/',
      urlCadastro: 'https://des-novocadin.np.estaleiro.serpro.gov.br/cadastro/api',
      urlCadastroREST: 'https://des-novocadin.np.estaleiro.serpro.gov.br/cadastro/apirest',
      urlRemessa: 'https://des-novocadin.np.estaleiro.serpro.gov.br/remessa/api/cadin',
      urlCadinSida: 'https://des-sida.estaleiro.serpro.gov.br/cadinsida-api/api/cadinsida',
      urlValidador: 'https://des-novocadin.np.estaleiro.serpro.gov.br/validador',
      urlExportador: 'https://des-novocadin.np.estaleiro.serpro.gov.br/exportador',
      urlBaseAuthSso: 'https://des-novocadin.np.estaleiro.serpro.gov.br/authsso/api'
    },
  ];

  constructor(ambiente: Ambientes = Ambientes.LOCAL) {
    this.ambiente = ambiente;
  }

  get nome(): string {
    return this._ambientes[this.ambiente].descricao;
  }

  get urlBase(): string {
    return this._ambientes[this.ambiente].urlBase;
  }

  get urlBaseAuth(): string {
    return this._ambientes[this.ambiente].urlBaseAuth;
  }

  get urlCadastro(): string {
    return this._ambientes[this.ambiente].urlCadastro;
  }
  get urlCadastroREST(): string {
    return this._ambientes[this.ambiente].urlCadastroREST;
  }
  get urlRemessa(): string {
    return this._ambientes[this.ambiente].urlRemessa;
  }
  get urlCadinSida(): string {
    return this._ambientes[this.ambiente].urlCadinSida;
  }
  get urlValidador(): string {
    return this._ambientes[this.ambiente].urlValidador;
  }
  get urlSaidaGov(): string {
    return this._ambientes[this.ambiente].urlSaidaGov;
  }

  get urlExportador(): string {
    return this._ambientes[this.ambiente].urlExportador;
  }

  get urlBaseAuthSso(): string {
    return this._ambientes[this.ambiente].urlBaseAuthSso;
  }

  setAmbiente(a: number) {
    this.ambiente = a;
  }

  setAmbientePorLocal(local: string) {
    const ambiente = this._ambientes.find((a: IAmbiente) => a.local === local);
    if (ambiente) {
      this.ambiente = ambiente.ambiente;
    }
  }
}
