/* eslint-disable @angular-eslint/component-selector */

import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { v4 } from 'uuid';

type TTiposSelect = 'single' | 'multiple' | 'popup';
// enum ETiposSelect {
//   single = 'single',
//   multiple = 'multiple',
//   popup = 'popup',
// }

interface IItemLista {
  dados: any;
  selected: boolean;
  id: string;
  itemValue: any;
  itemLabel: string;
}

@Component({
  selector: 'br-select',
  templateUrl: './br-select.component.html',
  styleUrls: ['./br-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrSelect2Component),
      multi: true,
    },
  ],
})
export class BrSelect2Component implements OnInit, OnChanges {
  @Input() titulo: string;
  @Input() placeholder = 'Selecione um item';
  @Input() filtroSelect = '';
  @Input() listaSelect: any[];
  @Input() itemLabel: string;
  @Input() itemValue: string;
  @Input() multiple = false;

  @Output() selectChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('brInput') brInput: ElementRef;
  @ViewChild('brMulti') brMulti: ElementRef;

  conteudo: any;
  conteudoAnterior: any;

  tipoSelect: TTiposSelect = 'single';

  mostraSelectList = false;

  lista: IItemLista[];
  listaVisivel: IItemLista[];
  inputSelect = '';
  itemSelect: any;

  composto = true;

  idValidos: string[] = [];

  private clickSub: any;
  private writeAtrasado: string;

  constructor(private clickService: ClickService, private util: UtilService) {
    // Fonte: https://stackoverflow.com/questions/51150422/how-to-detect-click-outside-of-an-element-in-angular
    // TODO: DESABILITAR APENAS PARA TESTE DE SELEÇÃO MÚLTIPLA
    // this.renderer.listen('window', 'mousedown', (e: Event) => {
    //   // console.log('txtInput', e.target === this.txtInput.nativeElement);
    //   // console.log('brInput', this.brInput.nativeElement.contains(e.target));
    //   // if (!this.brInput.nativeElement.contains(e.target)) {
    //   //   console.log('fecharSelectList');
    //   //   this.fecharSelectList();
    //   // }
    // });
  }

  // Detecção de cliques fora do controle ...
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.clickService.documentClickedTarget.next(event.target);
  }

  clickUnsubscribe() {
    this.clickSub?.unsubscribe();
  }

  clickSubscribe() {
    if (!this.clickSub || this.clickSub.closed) {
      this.clickSub = this.clickService.documentClickedTarget.subscribe((target) => this.documentListener(target));
    }
  }

  documentListener(target: any): void {
    if (
      !(this.brInput.nativeElement.contains(target) || (this.multiple && this.brMulti?.nativeElement.contains(target)))
    ) {
      this.fecharSelectList();
    }
  }
  //... Detecção de cliques fora do controle

  ngOnInit() {
    if (this.multiple) {
      this.tipoSelect = 'multiple';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtroSelect) {
      this.inputSelect = changes.filtroSelect.currentValue;
    }
    if (changes.listaSelect) {
      this.composto = this.itemLabel?.length > 0 && this.itemValue?.length > 0;
      if (changes.listaSelect.currentValue) {
        this.lista = this.carregarLista(changes.listaSelect.currentValue);
        if (this.writeAtrasado) {
          this.definirInput(this.writeAtrasado);
          // this.writeValue(this.writeAtrasado);
        }
        this.definirSelectList(this.inputSelect);
      }
    }
  }

  // ControlValueAccessor ...

  onChange: (_: any) => void = (_: any) => {};
  onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.filtroSelect = this.getInputSelect(value);
    this.definirInput(value);

    // value = String(value);
    // this.filtroSelect = this.getInputSelect(value);
    // this.conteudo = this.getItem(value);

    // this.inputSelect = this.filtroSelect || value;
    // this.writeAtrasado = this.filtroSelect === undefined ? value : '';

    // this.filtroSelect = value;
    // this.conteudo = value;
    this.tratarAlteracoes();
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  definirInput(value: string) {
    if (value) {
      value = String(value);
    }
    this.filtroSelect = this.getInputSelect(value);
    this.conteudo = this.getItem(value);
    this.inputSelect = this.filtroSelect || value;
    this.writeAtrasado = this.filtroSelect === undefined ? value : '';
  }

  tratarAlteracoes() {
    // this.conteudo = this.itemSelect?.dados;
    if (this.conteudo) {
      if (this.conteudo !== this.conteudoAnterior) {
        const valor = this.getConteudoValue();
        this.onChange(valor);
        this.selectChange.emit(valor);
      }
      this.conteudoAnterior = this.conteudo;
    }
  }

  // ... ControlValueAccessor

  abrirSelectList() {
    this.clickSubscribe();
    this.listaVisivel = [...this.lista];
    this.inputSelect = '';
    this.mostraSelectList = true;
  }

  /**
   * Seleciona um item da lista a partir de um texto (para valor inicial)
   *
   * @param textoFiltro Texto a ser selecionado
   */
  definirSelectList(textoFiltro: string) {
    const achou = this.lista.find((item) => this.compararPartesTexto(this.getItemLabel(item), textoFiltro));
    if (achou) {
      this.itemSelect = achou;
    }
  }

  /**
   * Filtra exibição da lista a partir de texto
   *
   * @param textoFiltro texto a ser filtrado
   */
  filtrarSelectList(textoFiltro: string): void {
    if (!textoFiltro) {
      this.listaVisivel = [...this.lista];
      return;
    }
    this.listaVisivel = this.lista.filter((item) => this.compararPartesTexto(this.getItemLabel(item), textoFiltro));

    // console.log('listaVisivel:' + this.listaVisivel.length);
    if(this.listaVisivel.length===1){
      this.mostraSelectList = true;
      this.fecharSelectList(this.listaVisivel[0]);
    }
  }

  /**
   * Fecha a lista de seleção, retorna valor(es) selecionado(s)
   *
   * @param item Item selecionado (opcional)
   */
  fecharSelectList(item?: any) {
    if (this.mostraSelectList) {
      this.conteudo = this.multiple ? this.fecharSelectListMultiple() : this.fecharSelectListSingle(item);
      this.tratarAlteracoes();
      this.mostraSelectList = false;
      this.clickUnsubscribe();
    }
  }

  fecharSelectListSingle(item?: any): any {
    this.itemSelect = item || this.itemSelect;
    if (this.itemSelect) {
      this.inputSelect = this.getItemLabel(this.itemSelect);
      return this.itemSelect.dados;
    }
    return null;
  }

  fecharSelectListMultiple(): any[] {
    const itensSelecionados = this.obterItensSelecionados();
    if (itensSelecionados) {
      return itensSelecionados;
    }
    return null;
  }

  /**
   * Abre ou fecha lista de seleção
   */
  toggleSelectList(): void {
    if (this.mostraSelectList) {
      this.fecharSelectList();
    } else {
      this.abrirSelectList();
    }
  }

  /**
   * Gera um id único de html
   *
   * @param prefixo prefixo para o id
   * @returns id gerado
   */
  getId(prefixo: string): string {
    const x = v4();
    const id = `${prefixo}-${x}`;
    return id;
  }

  obterItensSelecionados(): any[] {
    const retorno = this.lista.filter((i) => i.selected).map((i) => i.dados);
    return retorno;
  }

  /**
   * Eventos
   */

  selTodosOnClick(event: any) {
    this.listaVisivel.forEach((i) => (i.selected = event.currentTarget.checked));
  }

  selItemOnClick(item: IItemLista) {
    item.selected = !item.selected;
  }

  /**
   * Métodos privados
   */

  private carregarLista(values: any[]): IItemLista[] {
    const retorno = values.map((i) => ({
      id: this.getId('sel'),
      dados: i,
      selected: false,
      itemValue: this.composto ? i[this.itemValue] : i,
      itemLabel: this.composto ? i[this.itemLabel] : i,
    }));
    return retorno;
  }

  private getItemLabel(item: any): string {
    return item.itemLabel;
  }

  private compararPartesTexto(s1: string, s2: string): boolean {
    const retorno = this.util.removerAcentos(s1).indexOf(this.util.removerAcentos(s2)) > -1;
    return retorno;
  }

  private getItem(valor: string): IItemLista {
    if (this.itemValue) {
      if (!this.lista) {
        return null;
      }

      const item = this.lista.find((i) => String(i.dados[this.itemValue]) === valor);
      if (item) {
        const retorno = item;
        return retorno;
      }
      return null;
    } else {
      const item = this.lista.find((i) => i.dados === valor)?.dados;
      return item;
    }
  }

  private getInputSelect(valor: string): string {
    if (this.itemValue) {
      if (!this.lista) {
        return undefined;
      }

      const item = this.lista.find((i) => String(i.dados[this.itemValue]) === valor);
      // const item = this.lista.find((i) => i.dados[this.itemValue] === valor);
      if (item) {
        const retorno = item.dados[this.itemLabel];
        return retorno;
      }
      return valor;
    }
    return valor;
  }

  private getConteudoValue(): string {
    const conteudo = this.itemValue
      ? this.conteudo.dados
        ? this.conteudo.dados[this.itemValue]
        : this.conteudo[this.itemValue]
      : this.conteudo;
    return conteudo;
  }

  // private getItemValue(item: any): any {}
}

@Injectable({ providedIn: 'root' })
export class ClickService {
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
}
