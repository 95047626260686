import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MensagemService {

  constructor(private toastCtrl: ToastController) { }

  toastSuccess(mensagem: string, duracao?: number){
    this.presentToast('success', 'toast-success', 'checkmark-circle', mensagem, duracao);
  }
  toastDanger(mensagem: string, duracao?: number){
    this.presentToast('danger', 'toast-danger', 'close-circle', mensagem, duracao);
  }
  toastWarning(mensagem: string, duracao?: number){
    this.presentToast('warning', 'toast-warning', 'warning', mensagem, duracao);
  }

  private async presentToast(tipo: string, classe: string, icone: string, mensagem: string, duracao: number) {
    const tempo = (duracao===undefined) ? 7000 : duracao;
    const classes = ['toast'];
    classes.push(classe);

    const toast = await this.toastCtrl.create({
      message: mensagem,
      duration: tempo,
      position: 'top',
      cssClass: classes,
      icon: icone,
      buttons: [
        { text: 'X', role: 'cancel', handler: () => {} }
      ]
    });

    await toast.present();
  }
}
