export class UsuarioModel {
  public token: any = ''; // consultas com token
  public funcionalidades: any[] = [];
  public instituicao = undefined;
  public visaoCidadao = false;

  private iss = ''; // identificador da aplicação
  private cpf = '';
  private iat = ''; // timestamp de início do token
  private exp = ''; // timestamp de expiração do token
  private inicioToken = '';
  private expiracaoToken = '';
  private nome = '';
  private usuarioId = 0;
  private telefone = '';
  private email = '';
  private perfilCadin = '';
  private nivel = ''; // 3=ouro, 2=prata, 1=bronze
  private empresas = [];
  private logado = false;

  constructor() {}

  limparUsuario() {
    this.iss = '';
    this.cpf = '';
    this.iat = '';
    this.exp = '';
    this.inicioToken = '';
    this.expiracaoToken = '';
    this.nome = '';
    this.usuarioId = 0;
    this.telefone = '';
    this.email = '';
    this.perfilCadin = '';
    this.nivel = '';
    this.funcionalidades = [];
    this.instituicao = '';
    this.token = '';
    this.logado = false;
    this.visaoCidadao = false;
    this.empresas = [];

    this.getParametroLS('JWT', true); // limpa o LS
    this.getParametroLS('TOKEN_JWT', true); // limpa o LS
  }

  timestampToDateHour(ts: any) {
    const d = new Date(ts * 1000);
    return (
      d.getFullYear().toString() +
      '/' +
      this.zeroEsq(d.getMonth() + 1) +
      '/' +
      this.zeroEsq(d.getDate()) +
      ' ' +
      this.zeroEsq(d.getHours()) +
      ':' +
      this.zeroEsq(d.getMinutes()) +
      ':' +
      this.zeroEsq(d.getSeconds())
    );
  }
  zeroEsq(valor: number) {
    const DEZ = 10; // completa com um 0 à esquerda
    return valor < DEZ ? '0'.concat(valor.toString()) : valor.toString();
  }

  setUsuario() {
    const usu: any = this.getParametroLS('JWT', false); // false = mantém os dados na LS
    // console.log('[setUsuario]usu:' + JSON.stringify(usu));

    if (usu.nome === undefined) {
      this.limparUsuario();
    } else {
      // testes
      // usu.niveis = [{dataAtualizacao: '2023-07-12T16:21:54.433-0300', id: '3'},
      //               {dataAtualizacao: '2023-07-12T16:21:54.433-0300', id: '2'}];
      this.iss = usu.iss;
      this.cpf = usu.sub;
      this.iat = usu.iat;
      this.exp = usu.exp;
      this.inicioToken = this.timestampToDateHour(this.iat);
      this.expiracaoToken = this.timestampToDateHour(this.exp);
      this.nome = usu.nome;
      this.usuarioId = usu.idUsuarioCadin;
      this.telefone = usu.telefone;
      this.email = usu.email;
      this.perfilCadin = usu.perfilCadin || 'Cidadão';
      this.nivel = this.getDescricaoNivel(usu.niveis);
      this.logado = true;
      this.token = this.getParametroLS('TOKEN_JWT', false); // false = mantém os dados na LS
      this.funcionalidades = usu.funcionalidades || [];
      this.instituicao = usu.instituicao;
      this.empresas = usu.empresas || [];

      // Usuários de teste ----------------------------------------------------------------------
      const nomeUsuario = this.getNome().toUpperCase().trim();
      if (
        nomeUsuario === 'MASTER DA SILVA' ||
        nomeUsuario === 'CADGER DA SILVA' ||
        nomeUsuario === 'CADUSU DA SILVA' ||
        nomeUsuario === 'TRANSACAO DA SILVA' ||
        nomeUsuario === 'CONSULTA DA SILVA' ||
        nomeUsuario === 'FULANO BELTRANO SICRANO DA SILVA'
      ) {
        const d = new Date();
        this.setInicioToken(this.dataHoraFormatada(d));
        d.setDate(d.getDate() + 1);
        this.setExpiracaoToken(this.dataHoraFormatada(d));
      }
      // Usuários de teste ----------------------------------------------------------------------
    }
  }
  dataHoraFormatada(d: Date) {
    return (
      d.getFullYear().toString() +
      '/' +
      this.zeroEsq(d.getMonth() + 1) +
      '/' +
      this.zeroEsq(d.getDate()) +
      ' ' +
      this.zeroEsq(d.getHours()) +
      ':' +
      this.zeroEsq(d.getMinutes()) +
      ':' +
      this.zeroEsq(d.getSeconds())
    );
  }

  setInicioToken(ini: string) {
    this.inicioToken = ini;
  }
  setExpiracaoToken(exp: string) {
    this.expiracaoToken = exp;
  }

  getDescricaoNivel(niveis: any) {
    let r = '';
    let id = '';
    let maiorId = '0';

    try {
      for (const nivel of niveis) {
        id = nivel.id;

        if (maiorId < id) {
          maiorId = id;
          if (id === '3') {
            r = 'Ouro';
          } else if (id === '2') {
            r = 'Prata';
          } else if (id === '1') {
            r = 'Bronze';
          }
        }
      }
      //const id = niveis[0].id;
    } catch (e) {
      // não faz nada
    }
    return r;
  }

  getIss() {
    return this.iss;
  }
  getCpf() {
    return this.cpf;
  }
  getIat() {
    return this.iat;
  }
  getExp() {
    return this.exp;
  }
  getInicioToken() {
    return this.inicioToken;
  }
  getExpiracaoToken() {
    return this.expiracaoToken;
  }
  getNome() {
    return this.nome;
  }
  getUsuarioId() {
    return this.usuarioId;
  }
  getTelefone() {
    return this.telefone;
  }
  getEmail() {
    return this.email;
  }
  getPerfilCadin() {
    return this.perfilCadin;
  }
  getLogado() {
    return this.logado;
  }
  getNivel() {
    return this.nivel;
  }
  getFuncionalidades() {
    return this.funcionalidades;
  }

  getInstituicao() {
    return this.instituicao;
  }

  getVisaoCidadao() {
    return this.visaoCidadao;
  }

  getEmpresas() {
    return this.empresas;
  }

  // funções do LocalStorage
  setParametroLS(chave: string, parametro: any) {
    // localStorage.setItem(chave, JSON.stringify(parametro));
    sessionStorage.setItem(chave, JSON.stringify(parametro));
  }

  getParametroLS(chave: string, limpar: boolean = true) {
    let r: any[] = [];
    // const ls = localStorage.getItem(chave);
    const ls = sessionStorage.getItem(chave);

    if (ls != null && ls !== undefined) {
      r = JSON.parse(ls);
      if (limpar) {
        this.setParametroLS(chave, []);
      }
    }
    return r;
  }
}
