/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'authenticate',
    loadChildren: () => import('./pages/authenticate/authenticate.module').then((m) => m.AuthenticatePageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'principal',
    loadChildren: () => import('./pages/principal/principal.module').then((m) => m.PrincipalPageModule),
  },
  {
    path: 'novidades',
    loadChildren: () =>
      import('./pages/exibir-novidades/exibir-novidades.module').then((m) => m.ExibirNovidadesPageModule),
  },
  {
    path: '**',
    redirectTo: '',
  },

  // {
  //   path: 'consulta-cidadao-historico',
  //   loadChildren: () => import('./pages/consulta-cidadao-historico/consulta-cidadao-historico.module').then( m => m.ConsultaCidadaoHistoricoPageModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
