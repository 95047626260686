import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ExibirNovidadesPageRoutingModule } from './exibir-novidades-routing.module';

import { ExibirNovidadesPage } from './exibir-novidades.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ExibirNovidadesPageRoutingModule],
  declarations: [ExibirNovidadesPage],
})
export class ExibirNovidadesPageModule {}

