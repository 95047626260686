import { ETiposUsuarioNovidade } from '../cadastro-novidades/cadastro-novidades.model';

export interface IItemNovidade {
  tipoUsuario: ETiposUsuarioNovidade;
  txHtml: string;
  data: string;
  oculto: boolean;
}

export interface INovidadeStorage {
  oculto: boolean;
  novidades: IItemNovidadeStorage[];
}

export interface IItemNovidadeStorage {
  tipoUsuario: ETiposUsuarioNovidade;
  oculto: boolean;
  data: string;
}

export class ViewNovidades {
  novidades: IItemNovidade[];
  oculto: boolean;

  constructor(entrada?: any[]) {
    this.oculto = false;
    this.novidades = entrada.map((i) => ({
      tipoUsuario: i.tpUsuario,
      txHtml: i.txHtml,
      data: new Date(i.dtAtualizacao).toISOString(),
      oculto: false,
    }));
    this.ocultarNovidadesDeStorage();
  }

  getNovidadePorTipo(tipoUsuario: ETiposUsuarioNovidade): IItemNovidade {
    const retorno = this.novidades.find((i) => i.tipoUsuario === tipoUsuario);
    return retorno;
  }

  ocultarNovidadesDeStorage() {
    const novidadesOcultas = this.getParametroLocalStorage('novidades');
    novidadesOcultas
      ?.filter((i: IItemNovidadeStorage) => i.oculto)
      .forEach((i: IItemNovidadeStorage) => {
        const novidade = this.getNovidadePorTipo(i.tipoUsuario);
        const dataOcultacao = i.data;
        const dataNovidade = novidade.data;
        novidade.oculto = dataOcultacao <= dataNovidade ? false : true;
      });
  }

  ocultarNovidade(tipoUsuario: ETiposUsuarioNovidade) {
    this.getNovidadePorTipo(tipoUsuario).oculto = true;
    const novidadesStorage: IItemNovidadeStorage[] = this.novidades.map((i) => ({
      tipoUsuario: i.tipoUsuario,
      oculto: i.oculto || false,
      data: new Date().toISOString(),
    }));
    this.setParametroLocalStorage('novidades', novidadesStorage);
  }

  setParametroLocalStorage(chave: string, parametro: any): void {
    localStorage.setItem(chave, JSON.stringify(parametro));
  }

  getParametroLocalStorage(chave: string, limpar?: boolean): any {
    const item = localStorage.getItem(chave);
    if (!item) {
      return null;
    }
    if (limpar) {
      localStorage.removeItem(chave);
    }
    return JSON.parse(item);
  }
}
