/* eslint-disable @typescript-eslint/naming-convention */

export enum TiposPessoa {
  Fisica = 1,
  Juridica = 2,
  Ambos = 3,
}

export enum PerfilUsuario {
  MASTER = 'Master',
  CADASTRADOR_GERAL = 'Cadastrador Geral',
  CADASTRADOR_USUARIO = 'Cadastrador de Usuário',
  ADMINISTRADOR = 'Administrador',
  TRANSACAO = 'Transação',
  AUDITOR = 'Auditor',
  AUDITOR_INSTITUCIONAL = 'Auditor Institucional',
  CONSULTA = 'Consulta',
  CIDADAO = 'Cidadão',
}

export enum PerfilHierarquia {
  // CADASTRADOR_USUARIO tem o mesmo nível de ADMINISTRADOR, pois um pode criar o outro
  MASTER = 1,
  CADASTRADOR_GERAL = 2,
  CADASTRADOR_USUARIO = 3,
  ADMINISTRADOR = 3,
  TRANSACAO = 5,
  AUDITOR = 6,
  AUDITOR_INSTITUCIONAL = 7,
  CONSULTA = 8,
  CIDADAO = 9,
}

export enum Funcionalidades {
  INICIO = 'Início',
  PGFN = 'PGFN',
  MENU = 'Menu Lateral',

  CON_INSTITUICAO = 'Consultar Instituição',
  INC_INSTITUICAO = 'Incluir Instituição',
  ALT_INSTITUICAO = 'Alterar Instituição',
  AGR_INSTITUICAO = 'Agrupar Instituição',
  DES_INSTITUICAO = 'Desativar Instituição',
  REA_INSTITUICAO = 'Reativar Instituição',
  DET_INSTITUICAO = 'Detalhar Instituição',
  EXP_INSTITUICAO = 'Exportar Instituição',

  CON_USUARIO = 'Consultar Usuário',
  DES_USUARIO = 'Desativar Usuário',
  REA_USUARIO = 'Reativar Usuário',
  DET_USUARIO = 'Detalhar Usuário',
  EXP_USUARIO = 'Exportar Usuário',
  INC_USUARIO = 'Incluir Usuário',
  ALT_USUARIO = 'Alterar Usuário',

  INC_NI = 'Incluir NI no Cadastro',
  SUS_NI = 'Suspender NI no Cadastro',
  EXC_NI = 'Retirar NI do Cadastro',
  BAI_NI = 'Baixar NI do Cadastro',
  CON_NI = 'Consultar NI para gerenciar o Cadastro', //Consultar cadastro
  CON_CONTRATANTE = 'Consulta contratante',
  CON_SIT = 'Consultar situação NI',
  CON_SIT_NI = 'Consultar situação meu NI',
  CON_CREDORA = 'Consulta Credora',
  CON_EMITIR_RELATORIO = 'Emitir Relatório Atualizado',

  UPLOAD_REMESSA = 'Upload de remessas',
  CON_REMESSA = 'Consulta de remessas',
  CON_CIDADAO = 'Consulta Cidadão',
  CON_CIDADAO_HISTORICO = 'Consulta Histórico Cidadão',

  CON_AUDITORIA = 'Consulta Auditoria',
  CON_PERMISSOES = 'Consulta Permissões',

  CON_INSTITUICAO_BACEN = 'Consultar Instituição Bacen',
  CON_REGISTRO_BACEN = 'Consultar Registro Bacen',
  CON_NI_BACEN = 'Consultar NI Bacen',

  REL_GERENCIAIS = 'Relatórios Gerenciais',
  REL_VOLUMETRIA = 'Consulta Volumetria',
  EXTRACAO_CARGA = 'Extração e Carga',


  CAD_NOVIDADES = 'Cadastrar Novidades',
}

export enum SortTypes {
  natural = 'natural',
  data = 'data',
  dataHora = 'dataHora',
}

export enum SortOrders {
  sortDescent = -1,
  sortNone = 0,
  sortAscent = 1,
}

export const TEMPO_FOCO = 200;
