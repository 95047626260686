import { Injectable } from '@angular/core';
import { TiposPessoa } from 'src/models/enums-model';

type TSeparadoresData = '/' | '-';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  //formatações
  deseditarValor(valor) {
    //remove tudo o que não é número e retorna o valor
    return valor === null || valor === undefined ? '' : valor.toString().replace(/\D/g, '');
  }

  limitarTamanhoString(str: string, tamanho: number) {
    return str.length > tamanho ? str.substring(0, tamanho) : str;
  }

  trim(str: string) {
    //trim completo - remove espaços à direita e à esquerda
    return str === undefined ? '' : str.toString().replace(/^\s+|\s+$/g, '');
  }

  ltrim(str: string) {
    //left trim - remove espaços à esquerda
    return str === undefined ? '' : str.replace(/^\s+/, '');
  }

  rtrim(str: string) {
    //right trim - remove espaços à direita
    return str === undefined ? '' : str.replace(/\s+$/, '');
  }

  completaZeros(valor: string, tamanho: number, esqDir?: string) {
    let ret: string;
    if (esqDir === 'D') {
      ret = this.trim(valor) + '0'.repeat(tamanho);
      ret = ret.substr(0, tamanho);
    } else {
      ret = '0'.repeat(tamanho) + this.trim(valor);
      ret = ret.substr(ret.length - tamanho, tamanho);
    }
    return ret;
  }

  formataTamanhoCampoNumerico(valor: string, tamanho: number) {
    let aux: string = this.deseditarValor(valor);
    if (aux.length > tamanho) {
      aux = aux.substr(0, tamanho);
    }
    return aux;
  }

  formataTamanhoCampo(valor: string, tamanho: number) {
    let aux: string = this.trim(valor);
    if (aux.length > tamanho) {
      aux = aux.substr(0, tamanho);
    }
    return aux;
  }

  formatarData(aData) {
    //formata uma data no formato dd/mm/yyyy
    let retorno = '';
    const data = this.deseditarValor(aData);

    switch (data.length) {
      case 0:
        retorno = data;
        break;
      case 1:
        retorno = data;
        break;
      case 2:
        retorno = data;
        break;
      case 3:
        retorno = data.substr(0, 2) + '/' + data.substr(2, 1);
        break;
      case 4:
        retorno = data.substr(0, 2) + '/' + data.substr(2, 2);
        break;
      case 5:
        retorno = data.substr(0, 2) + '/' + data.substr(2, 2) + '/' + data.substr(4, 1);
        break;
      case 6:
        retorno = data.substr(0, 2) + '/' + data.substr(2, 2) + '/' + data.substr(4, 2);
        break;
      case 7:
        retorno = data.substr(0, 2) + '/' + data.substr(2, 2) + '/' + data.substr(4, 3);
        break;
      default:
        retorno = data.substr(0, 2) + '/' + data.substr(2, 2) + '/' + data.substr(4, 4);
        break;
      // default:
      //   retorno = data;
    }
    return retorno;
  }

  formataDataInvertida(aData: any) {
    //formata uma data no formato dd/mm/yyyy
    let retorno = ''; //20181221
    const data = this.deseditarValor(aData);

    if (data === '') {
      return '';
    }
    return (retorno = data.substr(6, 2) + '/' + data.substr(4, 2) + '/' + data.substr(0, 4));
  }

  formataHora(aData: any) {
    //formata uma hora no formato HH/mm/ss
    let retorno = ''; //14:01:51
    const hora = this.deseditarValor(aData);

    if (hora === '') {
      return '';
    }
    return (retorno = hora.substr(8, 2) + ':' + hora.substr(10, 2) + ':' + hora.substr(12, 2));
  } //202309051401514410000"

  formataMoeda(v: any) {
    //formata moeda com 2 decimais
    let retorno = '';

    v = this.deseditarValor(v); //remove formatação
    v = Number(v).toString(); //remove zeros à esquerda

    switch (v.length) {
      case 0:
        retorno = '0,00';
        break;
      case 1:
        retorno = '0,0' + v;
        break;
      case 2:
        retorno = '0,' + v;
        break;
      default:
        let cont = 0;
        for (let i = v.length - 1; i >= 0; i--) {
          if (cont === 2) {
            retorno = v.substr(i, 1) + ',' + retorno; //coloca vírgula
          } else if ((cont - 2) % 3 === 0) {
            retorno = v.substr(i, 1) + '.' + retorno; //coloca pontos
          } else {
            retorno = v.substr(i, 1) + retorno;
          }
          cont++;
        }
    }
    return retorno;
  }

  formatarValor(valor: number | string, decimais: number = 2): string {
    // Formata valor com pontuação e 0 a 6 decimais
    const SEPARADOR_DECIMAL = ',';
    const SEPARADOR_MILHARES = '.';
    const PADDING = '000000';

    valor = parseInt(this.deseditarValor(valor), 10);

    let [integer, fraction = ''] = (valor || '').toString().split(SEPARADOR_DECIMAL);

    fraction = decimais > 0 ? SEPARADOR_DECIMAL + (fraction + PADDING).substring(0, decimais) : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, SEPARADOR_MILHARES);

    return integer + fraction;
  }

  formataMilhar(str: string) {
    let r = '';
    let s = '';

    // inverte a string
    for (const ch of str) {
      s = ch + s;
    }

    // máscara de milhar
    let conta = 0;
    for (const ch of s) {
      conta++;
      r = r !== '' && conta % 3 === 1 ? ch + '.' + r : ch + r;
    }
    return r;
  }

  formataUUID(s: string) {
    // formato: 8-4-4-4-12
    let r = s.toLowerCase().replace(/[^a-f0-9]/gi, ''); //apenas letras e números
    let tam = r.length;

    if (r.length > 32) {
      r = r.substring(0, 32);
      tam = 32;
    }

    if (tam > 20) {
      r =
        r.substring(0, 8) +
        '-' +
        r.substring(8, 12) +
        '-' +
        r.substring(12, 16) +
        '-' +
        r.substring(16, 20) +
        '-' +
        r.substring(20, tam);
    } else if (tam > 16) {
      r = r.substring(0, 8) + '-' + r.substring(8, 12) + '-' + r.substring(12, 16) + '-' + r.substring(16, tam);
    } else if (tam > 12) {
      r = r.substring(0, 8) + '-' + r.substring(8, 12) + '-' + r.substring(12, tam);
    } else if (tam > 8) {
      r = r.substring(0, 8) + '-' + r.substring(8, tam);
    }

    return r;
  }

  dataAMD(pData) {
    //Retorna a data no formato AAAAMMDD
    const dt = this.deseditarValor(pData);
    const amd = dt.substr(4, 4) + dt.substr(2, 2) + dt.substr(0, 2);
    return amd;
  }

  diffDias(ini: string, fim: string) {
    //retorna o número de dias entre duas datas
    //Entrada: datas no formato AAAA/MM/DD

    const di = new Date(ini);
    const df = new Date(fim);
    //let diff = df.valueOf() - di.valueOf();
    const diff = Math.abs(df.getTime() - di.getTime());
    const diffDias = Math.ceil(diff / (1000 * 3600 * 24));

    return diffDias;
  }

  dataDMA(pData) {
    //Retorna a data no formato AAAAMMDD
    const dt = this.deseditarValor(pData);
    const dma = dt.substr(6, 2) + dt.substr(4, 2) + dt.substr(0, 4);
    return dma;
  }

  dataFormatada(pData, separador) {
    //Retorna a data no formato DD/MM/AAAA
    let amd = '';
    let dt = '';

    if (pData != null) {
      dt = this.deseditarValor(pData);
      amd = dt.substr(6, 2) + separador + dt.substr(4, 2) + separador + dt.substr(0, 4);
    }
    return amd;
  }

  dataAMDFormatada(pData, separador) {
    //recebe data no formata DMA
    //Retorna a data no formato AAAA-MM-DD (separador informado)
    let amd = '';
    if (pData != null) {
      const dt = this.deseditarValor(pData);
      amd = dt.substr(4, 4) + separador + dt.substr(2, 2) + separador + dt.substr(0, 2);
    }
    return amd;
  }

  dataDMAFormatada(pData, separador) {
    //recebe data no formata DMA
    //Retorna a data no formato DD-MM-AAAA (separador informado)
    let dma = '';
    if (pData != null) {
      const dt = this.deseditarValor(pData);
      dma = dt.substr(0, 2) + separador + dt.substr(2, 2) + separador + dt.substr(4, 4);
    }
    return dma;
  }

  dataCorrente() {
    //retorna a data corrente no formato DDMMAAAA
    const dt = new Date();
    const dia = String(dt.getDate());
    const mes = String(dt.getMonth() + 1);
    const ano = String(dt.getFullYear());
    const hoje = (dia.length === 1 ? '0' + dia : dia) + (mes.length === 1 ? '0' + mes : mes) + ano;

    return hoje;
  }

  timestampToDateTime(ts: any) {
    if (ts !== null && ts !== undefined) {
      const dt = new Date(Number(ts));
      // console.log('ts:' + ts + ' - dt:' + dt);
      const d = dt.getDate();
      const m = dt.getMonth() + 1;
      const a = dt.getFullYear();
      const h = dt.getHours();
      const mm = dt.getMinutes();
      const r =
        this.completaZeros(d.toString(), 2) +
        '/' +
        this.completaZeros(m.toString(), 2) +
        '/' +
        a +
        ' ' +
        this.completaZeros(h.toString(), 2) +
        ':' +
        this.completaZeros(mm.toString(), 2);
      return r;
    }
    return '';
  }

  /**
   * Converte uma data string (DD/MM/AAAA) em Date
   *
   * @param dataDMA Data string no formato DD/MM/AAAA
   * @returns data no formato Date
   */
  dataToDate(dataDMA: string): Date {
    const retorno = new Date(Number(dataDMA.slice(-4)), Number(dataDMA.slice(3, 5)) - 1, Number(dataDMA.slice(0, 2)));
    return retorno;
  }

  horaCorrente() {
    const d = new Date();
    return (
      this.completaZeros(d.getHours().toString(), 2) +
      this.completaZeros(d.getMinutes().toString(), 2) +
      this.completaZeros(d.getSeconds().toString(), 2)
    );
  }

  timestampToDateHour(ts: any) {
    const d = new Date(ts * 1000);
    return (
      d.getFullYear().toString() +
      this.completaZeros((d.getMonth() + 1).toString(), 2) +
      this.completaZeros(d.getDate().toString(), 2) +
      this.completaZeros(d.getHours().toString(), 2) +
      this.completaZeros(d.getMinutes().toString(), 2) +
      this.completaZeros(d.getSeconds().toString(), 2)
    );
  }

  formatarCpf2(cpf: string | number): string {
    const tamanho = 11;
    if (typeof cpf === 'number') {
      cpf = String(cpf);
    }
    cpf = this.deseditarValor(cpf) as string;
    if (cpf.length > tamanho) {
      return undefined;
    }
    const matriz = cpf.padStart(tamanho, '0');
    const retorno = matriz.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    return retorno;
  }

  formatarCnpj2(cnpj: string | number): string {
    const tamanho = 14;
    if (typeof cnpj === 'number') {
      cnpj = String(cnpj);
    }
    cnpj = this.deseditarValor(cnpj) as string;
    if (cnpj.length > tamanho) {
      return undefined;
    }
    const matriz = cnpj.padStart(tamanho, '0');
    const retorno = matriz.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    return retorno;
  }

  formatarNI2(cpfCnpj: string | number): string {
    const retorno: string = this.deseditarValor(String(cpfCnpj));
    if (retorno.length <= 11) {
      return this.formatarCpf2(retorno);
    }
    if (retorno.length <= 14) {
      return this.formatarCnpj2(retorno);
    }
  }

  formatarCpf(pCpf) {
    let ret: string = this.deseditarValor(pCpf);
    switch (ret.length) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 1);
        break;
      case 5:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 2);
        break;
      case 6:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 3);
        break;
      case 7:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 3) + '.' + ret.substr(6, 1);
        break;
      case 8:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 3) + '.' + ret.substr(6, 2);
        break;
      case 9:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 3) + '.' + ret.substr(6, 3);
        break;
      case 10:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 3) + '.' + ret.substr(6, 3) + '-' + ret.substr(9, 1);
        break;
      default:
        ret = ret.substr(0, 3) + '.' + ret.substr(3, 3) + '.' + ret.substr(6, 3) + '-' + ret.substr(9, 2);
        break;
    }
    return ret;
  }

  formatarCnpj(pCnpj) {
    let ret: string = this.deseditarValor(pCnpj);
    switch (ret.length) {
      //99.999.999/9999-99
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 1);
        break;
      case 4:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 2);
        break;
      case 5:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3);
        break;
      case 6:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 1);
        break;
      case 7:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 2);
        break;
      case 8:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 3);
        break;
      case 9:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 3) + '/' + ret.substr(8, 1);
        break;
      case 10:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 3) + '/' + ret.substr(8, 2);
        break;
      case 11:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 3) + '/' + ret.substr(8, 3);
        break;
      case 12:
        ret = ret.substr(0, 2) + '.' + ret.substr(2, 3) + '.' + ret.substr(5, 3) + '/' + ret.substr(8, 4);
        break;
      // eslint-disable-next-line max-len
      case 13:
        ret =
          ret.substr(0, 2) +
          '.' +
          ret.substr(2, 3) +
          '.' +
          ret.substr(5, 3) +
          '/' +
          ret.substr(8, 4) +
          '-' +
          ret.substr(12, 1);
        break;
      // eslint-disable-next-line max-len
      default:
        ret =
          ret.substr(0, 2) +
          '.' +
          ret.substr(2, 3) +
          '.' +
          ret.substr(5, 3) +
          '/' +
          ret.substr(8, 4) +
          '-' +
          ret.substr(12, 2);
        break;
    }
    return ret;
  }

  formatarNIPorTipo(ni: any, tipo: TiposPessoa): string {
    switch (tipo) {
      case TiposPessoa.Fisica:
        return this.formatarCpf2(ni);
      case TiposPessoa.Juridica:
        return this.formatarCnpj2(ni);
      default:
        return String(ni);
    }
  }

  formatarNI(pNi) {
    let ret: string = this.deseditarValor(pNi);
    if (ret.length <= 11) {
      ret = this.formatarCpf(ret);
    } else {
      ret = this.formatarCnpj(ret);
    }
    return ret;
  }

  formatarInscricao(pInsc) {
    let ret: string = this.deseditarValor(pInsc);
    switch (ret.length) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3);
        break;
      case 4:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 4);
        break;
      case 5:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5);
        break;
      case 6:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5) + '.' + ret.substring(5, 6);
        break;
      case 7:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5) + '.' + ret.substring(5, 7);
        break;
      case 8:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5) + '.' + ret.substring(5, 8);
        break;
      case 9:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5) + '.' + ret.substring(5, 9);
        break;
      case 10:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5) + '.' + ret.substring(5, 10);
        break;
      case 11:
        ret = ret.substring(0, 2) + '.' + ret.substring(2, 3) + '.' + ret.substring(3, 5) + '.' + ret.substring(5, 11);
        break;
      // eslint-disable-next-line max-len
      case 12:
        ret =
          ret.substring(0, 2) +
          '.' +
          ret.substring(2, 3) +
          '.' +
          ret.substring(3, 5) +
          '.' +
          ret.substring(5, 11) +
          '-' +
          ret.substring(11, 12);
        break;
      // eslint-disable-next-line max-len
      default:
        ret =
          ret.substring(0, 2) +
          '.' +
          ret.substring(2, 3) +
          '.' +
          ret.substring(3, 5) +
          '.' +
          ret.substring(5, 11) +
          '-' +
          ret.substring(11, 13);
        break;
    }
    return ret;
  }

  /**
   * Remove espaços extra e acentos entre partes de uma string
   *
   * @param nome nome a ser formatado
   * @returns nome formatado
   */
  formatarPartesNome(nome: string): string {
    const retorno = nome
      .split(' ')
      .filter((i) => i.length)
      .map((i) => this.removerAcentos(i.trim()))
      .join(' ');
    return retorno;
  }

  calculaDvNi(ni) {
    let dv = '';
    let quo;
    let resto;
    let novodv;

    for (let k = 1; k <= 2; k++) {
      const tam = ni.length;
      let soma = 0;
      let mult = 1;
      for (let i = tam - 1; i >= 0; i--) {
        mult = mult + 1;
        soma = soma + parseInt(ni.substring(i, i + 1), 10) * mult;
      }
      quo = soma / 11;
      quo = parseInt(quo, 10);
      resto = soma - quo * 11;

      novodv = 0;
      if (resto > 1) {
        novodv = 11 - resto;
      }
      dv = dv + novodv;
      ni = ni + novodv;
    }
    return dv;
  }

  primeiraMaiuscula(s: string) {
    //primeira letra maiúscula
    let r = '';
    for (let i = 0; i < s.length; i++) {
      r += s.substr(i, 1).toLowerCase();
      if (i === 0) {
        r = r.toUpperCase();
      }
    }
    return r;
  }

  //validações
  emailValido(email: string) {
    email = this.testaStringNula(email);
    const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(email);
  }

  testaStringNula(s: string) {
    return s === undefined || s === null ? '' : s;
  }

  //datas
  dataValida(paramDt) {
    //Verifica se a data é válida
    const dt = this.deseditarValor(paramDt);

    if (dt.length !== 8) {
      return false;
    }
    const dia = dt.substr(0, 2);
    const mes = dt.substr(2, 2);
    const ano = dt.substr(4, 4);

    //valida ano
    if (ano < '1900' || ano > '2050') {
      return false;
    }

    //valida mês
    if (mes < '01' || mes > '12') {
      return false;
    }

    //valida dia
    if (mes === '02' && this.anoBissexto(ano)) {
      if (dia < '01' || dia > '29') {
        return false;
      }
    } else if (mes === '02' && (dia < '01' || dia > '28')) {
      return false;
    }

    if ((mes === '04' || mes === '06' || mes === '09' || mes === '11') && (dia < '01' || dia > '30')) {
      return false;
    }

    if (dia < '01' || dia > '31') {
      return false;
    }

    return true;
  }

  anoBissexto(ano) {
    //Verifica se o ano é bissexto
    let bissexto = false;

    if (ano % 100 === 0) {
      if (ano % 400 === 0) {
        bissexto = true;
      }
    } else if (ano % 4 === 0) {
      bissexto = true;
    }
    return bissexto;
  }

  //Validação de NI
  niValido(param: string): boolean {
    param = this.deseditarValor(param);
    if (param.length <= 11) {
      return this.cpfValido(param);
    } else {
      return this.cnpjValido(param);
    }
  }

  cpfValido(param: string): boolean {
    param = this.deseditarValor(param);

    if (param.length !== 11) {
      return false;
    }

    // CPF com o mesmo número
    for (let i = 0; i <= 9; i++) {
      let aux = '';
      for (let j = 0; j < 11; j++) {
        aux = aux.concat(i.toString());
      }
      // console.log('aux:' + aux + ' - param:' + param);
      if (param === aux) {
        return false;
      }
    }

    const x = this.calculaDvMod11aInf(param.slice(0, -2));
    if (x === param.slice(-2)) {
      return true;
    } else {
      return false;
    }
  }

  cnpjValido(param: string): boolean {
    param = this.deseditarValor(param);
    const x = this.calculaDvMod11(param.slice(0, 12));

    if (x === param.slice(-2)) {
      return true;
    } else {
      return false;
    }
  }

  // Cálculo de DV de CNPJ e Inscrição - Adaptada do javascript do Sida ASP
  // @param valor CNPJ (sem DV) a ser calculado
  calculaDvMod11(valor: string) {
    // Calcula DV módulo 11 (adaptada do javascript do Sida ASP)
    let dv = '';
    for (let k = 1; k <= 2; k++) {
      let soma = 0;
      let mult = 1;

      for (let i = valor.length - 1; i >= 0; i--) {
        mult++;
        soma = soma + parseInt(valor.substring(i, i + 1), 10) * mult;
        mult = mult === 9 ? 1 : mult;
      }

      const quo = Math.floor(soma / 11);
      const resto = soma - quo * 11;
      const nov = resto > 1 ? 11 - resto : 0;
      dv += nov;
      valor += nov;
    }
    return dv;
  }

  // Cálculo de DV de CPF - Adaptada do javascript do Sida ASP
  // @param valor CPF (sem DV) a ser calculado
  calculaDvMod11aInf(valor: string) {
    let dv = '';
    for (let k = 1; k <= 2; k++) {
      const tam = valor.length;
      let soma = 0;
      let mult = 1;
      for (let i = tam - 1; i >= 0; i--) {
        mult = mult + 1;
        soma = soma + parseInt(valor.substring(i, i + 1), 10) * mult;
      }

      const quo = Math.floor(soma / 11);
      const resto = soma - quo * 11;
      let novoDv = 0;
      if (resto > 1) {
        novoDv = 11 - resto;
      }
      //'DV = DV & Mid(Str(NovoDv), 2)
      dv += novoDv;
      valor += novoDv;
    }
    return dv;
  }

  //outras funções
  // Executa ordenação "natural", considerando números dentro do texto (eg. Item 1, Item 2, Item 10, Item 21, ...)
  // Fonte: https://stackoverflow.com/questions/21687907/typescript-sorting-an-array
  // @param a Item 1
  // @param b Item 2
  naturalCompare(a, b) {
    const ax = [];
    const bx = [];

    a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      ax.push([$1 || Infinity, $2 || '']);
    });
    b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) {
        return nn;
      }
    }

    return ax.length - bx.length;
  }

  ordenaListaPorId(lista: any) {
    let troca: any;
    for (let i = 0; i < lista.length - 1; i++) {
      for (let j = i + 1; j < lista.length; j++) {
        if (lista[i].id > lista[j].id) {
          troca = lista[i];
          lista[i] = lista[j];
          lista[j] = troca;
        }
      }
    }
    return lista;
  }
  ordenaListaPorHierarquia(lista: any) {
    let troca: any;
    for (let i = 0; i < lista.length - 1; i++) {
      for (let j = i + 1; j < lista.length; j++) {
        if (lista[i].hierarquia > lista[j].hierarquia) {
          troca = lista[i];
          lista[i] = lista[j];
          lista[j] = troca;
        }
      }
    }
    return lista;
  }

  codDecod(operacao: string, entrada: string) {
    // operação: C ou D (Codifica/Decodifica)
    const chaveAberta = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz!@#$%*()-_=+,<.>;:/?|[]{}&';
    const chaveFechada = 'aRb5TfVpAyS dn73CoOrcLEPsZqWxDe40jIK9wQYi2JUX8khHgF6MBN1Gl($)!*@#-]m|>};v&_/{+t<[=,u:.<?z';

    const chaveE = operacao === 'C' ? chaveAberta : chaveFechada; //entrada
    const chaveS = operacao === 'C' ? chaveFechada : chaveAberta; // saída
    let r = '';

    // percorre e codifica/decodifica a string

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < entrada.length; i++) {
      const c = entrada[i];
      const pos = chaveE.indexOf(c);
      r = r.concat(chaveS[pos]);
    }
    return r;
  }

  removeAcentos(s: string): string {
    let saida = '';
    let c = '';

    for (let i = 0; i < s.length; i++) {
      c = s.substring(i, i + 1);
      switch (c) {
        case 'ã':
          c = 'a';
          break;
        case 'á':
          c = 'a';
          break;
        case 'â':
          c = 'a';
          break;
        case 'à':
          c = 'a';
          break;
        case 'é':
          c = 'e';
          break;
        case 'ê':
          c = 'e';
          break;
        case 'í':
          c = 'i';
          break;
        case 'ó':
          c = 'o';
          break;
        case 'ô':
          c = 'o';
          break;
        case 'õ':
          c = 'o';
          break;
        case 'ú':
          c = 'u';
          break;
        case 'ç':
          c = 'c';
          break;
        case 'Ã':
          c = 'A';
          break;
        case 'Á':
          c = 'A';
          break;
        case 'Â':
          c = 'A';
          break;
        case 'À':
          c = 'A';
          break;
        case 'É':
          c = 'E';
          break;
        case 'Ê':
          c = 'E';
          break;
        case 'Í':
          c = 'I';
          break;
        case 'Ó':
          c = 'O';
          break;
        case 'Ô':
          c = 'O';
          break;
        case 'Õ':
          c = 'O';
          break;
        case 'Ú':
          c = 'U';
          break;
        case 'Ç':
          c = 'C';
          break;
      }
      saida = saida.concat(c);
    }
    return saida;
  }

  /**
   * Verifica se um texto faz parte de outro (ex. "fazenda" em "procuradoria geral da fazenda nacional")
   *
   * @param s1 texto menor
   * @param s2 texto maior
   * @returns true se texto for encontrado
   */
  compararTextoParcial(s1: string, s2: string): boolean {
    const retorno = this.removerAcentos(s1).indexOf(this.removerAcentos(s2)) > -1;
    return retorno;
  }

  /**
   * Converte texto em minúsculas e sem acentuação
   * Fonte: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
   *
   * @param s texto a ser processado
   * @returns texto em minúsculas e sem acentos
   */
  removerAcentos(s: string): string {
    return s
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '');
  }
  /**
   * Transforma uma data em formato numérico timestamp em string formatada
   *
   * @param timestamp Data numérica a ser formatada
   * @param formato Formato do retorno: 'DMA' (default) ou 'AMD'
   * @param separador Separador do retorno: '/' (default) ou '-'
   * @param incluirHoraMinutos Inclui hora:minutos no retorno
   * @param incluirSegundos Inclui :segundos no retorno
   * @param incluirMilissegundos Inclui .milissegundos no retorno
   * @returns Data em formato string
   */
  timestampDataFormatadaCompleta({
    timestamp,
    formato,
    separador,
    incluirData = true,
    incluirHoraMinutos,
    incluirSegundos,
    incluirMilissegundos,
  }: {
    timestamp: number | string;
    formato?: 'DMA' | 'AMD';
    separador?: TSeparadoresData;
    incluirData?: boolean;
    incluirHoraMinutos?: boolean;
    incluirSegundos?: boolean;
    incluirMilissegundos?: boolean;
  }): string {
    const retorno: string[] = [];
    if (timestamp) {
      separador = separador || '/';
      const data = new Date(Number(timestamp));
      const dataArray = {
        ano: String(data.getFullYear()),
        mes: this.completaZeros(String(data.getMonth() + 1), 2, 'E'),
        dia: this.completaZeros(String(data.getDate()), 2, 'E'),
        hora: this.completaZeros(String(data.getHours()), 2, 'E'),
        minutos: this.completaZeros(String(data.getMinutes()), 2, 'E'),
        segundos: this.completaZeros(String(data.getSeconds()), 2, 'E'),
        milissegundos: this.completaZeros(String(data.getMilliseconds()), 3, 'E'),
      };
      if (incluirData) {
        switch (formato || 'DMA') {
          case 'DMA':
            retorno.push(`${dataArray.dia}${separador}${dataArray.mes}${separador}${dataArray.ano}`);
            break;
          case 'AMD':
            retorno.push(`${dataArray.ano}${separador}${dataArray.mes}${separador}${dataArray.dia}`);
            break;
        }
      }
      if (incluirHoraMinutos) {
        retorno.push(` ${dataArray.hora}:${dataArray.minutos}`);
      }
      if (incluirSegundos) {
        retorno.push(`:${dataArray.segundos}`);
      }
      if (incluirMilissegundos) {
        retorno.push(`.${dataArray.milissegundos}`);
      }
    }
    return retorno.join('');
  }
}
