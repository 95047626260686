import { TipoInputDirective } from './../../directives/tipo.directive';
export enum ETiposUsuarioNovidade {
  normal = 1,
  cidadao = 2,
}

export interface IItemNovidadeDTO {
  idNovidades: number;
  txHtml: string;
  niUsuario: string;
  nrIP: string;
  dtAtualizacao: string;
  tpUsuario: number;
}

export interface IItemCadastroNovidade {
  tipo: ETiposUsuarioNovidade;
  texto: string;
}

export class ViewCadastroNovidade {
  textosNovidades: IItemCadastroNovidade[];
  tiposAlterados: ETiposUsuarioNovidade[];

  constructor(textosNovidades?: IItemCadastroNovidade[]) {
    if (textosNovidades) {
      this.textosNovidades = textosNovidades;
    }
    this.tiposAlterados = [];
  }

  getNovidadePorTipo(tipoUsuario: ETiposUsuarioNovidade): IItemCadastroNovidade {
    const retorno = this.textosNovidades.find((i) => i.tipo === tipoUsuario);
    return retorno;
  }

  getTextoPorTipo(tipoUsuario: ETiposUsuarioNovidade): string {
    const retorno = this.getNovidadePorTipo(tipoUsuario).texto;
    return retorno;
  }

  setTextoNovidade(tipoUsuario: ETiposUsuarioNovidade, textoEdicao: string) {
    const novidadeTipo = this.getNovidadePorTipo(tipoUsuario);
    if (!novidadeTipo) {
      return;
    }
    if (textoEdicao !== novidadeTipo.texto) {
      if (this.tiposAlterados.indexOf(tipoUsuario) < 0) {
        this.tiposAlterados.push(tipoUsuario);
        novidadeTipo.texto = textoEdicao;
      }
    }
  }
  getAlteracoes(): IItemCadastroNovidade[] {
    const retorno = this.tiposAlterados.map((i) => this.getNovidadePorTipo(i));
    return retorno;
  }

  limparAlteracoes() {
    this.tiposAlterados = [];
  }
}
