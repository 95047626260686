/* eslint-disable max-len */
import { NavigationExtras, Router } from '@angular/router';
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private router: Router, public http: HttpClient, public global: GlobalService) {}

  doGet(url: string) {
    // return this.http.get(url);
    return this.doGetToken(url);
  }

  doPost(url: string, parametros: any, fazRenew: boolean = true) {
    // return this.http.post(url, parametros);
    return this.doPostToken(url, parametros);
  }

  doPut(url: string, parametros: any, fazRenew: boolean = true) {
    // return this.http.put(url, parametros);
    return this.doPutToken(url, parametros);
  }

  // com token de autenticação
  doGetToken(url: string) {
    const headers = this.montaHeadersToken();
    // console.log('headers:' + JSON.stringify(headers));
    return this.http.get(url, { headers });
  }

  doPostToken(url: string, parametros) {
    const headers = this.montaHeadersToken();
    return this.http.post(url, parametros, { headers });
  }

  doPutToken(url: string, parametros) {
    const headers = this.montaHeadersToken();
    return this.http.put(url, parametros, { headers });
  }

  doPostBlob(url: string, parametros: any) {
    const headers = this.montaHeadersToken();
    return this.http.post(url, parametros, {headers, observe: 'response', responseType: 'blob'});
  }

  doGetBlob(url: string) {
    const headers = this.montaHeadersToken();
    // eslint-disable-next-line object-shorthand
    return this.http.get(url, {headers: headers, observe: 'response', responseType: 'blob' as 'json'});
  }

  montaHeadersToken() {
    let token = '';

    if (this.global.usuario != null) {
      if (this.global.usuario.token != null) {
        token = this.global.usuario.token;
      }
    }

    if (token !== '') {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (this.global.usuario == null ? '' : this.global.usuario.token),
      });
      return headers;
    } else {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return headers;
    }
  }

  // envio de remessa com token
  doPostTokenRemessa(url: string, parametros: any) {
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + (this.global.usuario == null ? '' : this.global.usuario.token),
    });
    return this.http.post(url, parametros, { headers });
  }

  geraTokenTeste(p: any) {
    // recebe um json com os dados para gerar o token de teste
    const url = 'https://des-novocadin.estaleiro.serpro.gov.br/authsso/api/gerartokenvalido';

    return new Promise((resolve, reject) => {
      this.http.post(url, p).subscribe(
        (res: any) => {
          // this.global.consolelog('res:' + JSON.stringify(res));
          // this.global.consolelog('Gerou token de teste');
          resolve(res.Bearer);
        },
        (err) => {
          this.global.consolelog('err:' + JSON.stringify(err));
          reject('');
        }
      );
    });
  }

  doGetPromise(url: string){
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        (res: any) => { resolve(true); },
        (err) => { reject(false); }
      );
    });
  }

  // Cadin Sida
  doGetCadinSida(url: string, parametros?: any) {
    return this.http.get(url, parametros);
  }
  doPostCadinSida(url: string, parametros?: any) {
    return this.http.post(url, parametros);
  }
  // Cadin Sida

  definirParametrosNav(parametros: any): NavigationExtras {
    const retorno: NavigationExtras = {
      state: {},
    };
    Object.keys(parametros).forEach((chave) => {
      retorno.state[chave] = parametros[chave];
    });
    return retorno;
  }

  obterParametrosNav(chave: string): any {
    const navigationExtrasState = this.router.getCurrentNavigation()?.extras.state;
    // console.log('navext:' + JSON.stringify(navigationExtrasState));
    if (navigationExtrasState) {
      return navigationExtrasState[chave];
    }
    return undefined;
  }

  // funções do LocalStorage
  setParametroLS(chave: string, parametro: any) {
    // localStorage.setItem(chave, JSON.stringify(parametro));
    sessionStorage.setItem(chave, JSON.stringify(parametro));
  }

  getParametroLS(chave: string, limpar: boolean = true) {
    let r: any[] = [];
    // const ls = localStorage.getItem(chave);
    const ls = sessionStorage.getItem(chave);
    if (ls != null && ls !== undefined) {
      r = JSON.parse(ls);

      if (limpar) {
        this.setParametroLS(chave, []);
      }
    }
    // console.log('ls:' + JSON.stringify(ls));
    return r;
  }
}
