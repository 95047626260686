import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { MensagemService } from 'src/app/services/mensagem.service';
import { ETiposUsuarioNovidade } from './cadastro-novidades.model';

@Injectable({ providedIn: 'root' })
export class CadastroNovidadesService {
  constructor(private global: GlobalService, private api: ApiService, private mensagem: MensagemService) {}

  consultaNovidades$(tpUsuario: ETiposUsuarioNovidade): Observable<any> {
    const url = `${this.global.ambiente.urlBase}/novidades/consultaNovidades/${String(tpUsuario)}`;
    return this.api.doGet(url).pipe(
      catchError((err) => {
        this.mensagem.toastDanger('Erro ao carregar novidades');
        return throwError(err);
      })
    );
  }

  adicionaNovidades$(tpUsuario: ETiposUsuarioNovidade, txHtml: string): Observable<any> {
    const url = `${this.global.ambiente.urlBase}/novidades/adicionaNovidades`;
    const parametros = {
      tpUsuario,
      txHtml,
    };
    return this.api.doPut(url, parametros).pipe(
      catchError((err) => {
        this.mensagem.toastDanger('Erro ao gravar novidades');
        return throwError(err);
      })
    );
  }
}
